<template>
  <div class="vps-detail-view">
    <transition name="bubble">
      <!--      <div v-if="loading">-->
      <!--        <base-loader class="stack-detail-view__loader-item" />-->
      <!--      </div>-->
      <!--      <page-block v-else class="vps-detail-view__block">-->
      <page-block class="vps-detail-view__block">
        <main-card>
          <div class="addons">
            <div v-for="(item, i) in addons" :key="i" class="addons__item">
              <div class="addons__label standart-title">
                {{ $t(`${item.name}`) }}
              </div>
              <div class="addons__value standart-text">
                {{ item.value }}
              </div>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
import { format } from 'date-fns';
export default {
  name: 'BalancerView',
  components: {
    MainCard,
  },
  mixins: [providerChange],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
    float: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localDisk: null,
      // isLoading: true,
    };
  },
  computed: {
    type() {
      return this.$store.state.moduleStack.flavorprofiles;
    },
    addons() {
      const specs = this.tariff;
      let list = [];
      const port = this.$store.state.moduleStack.ports.filter(x => x.name.includes('octavia'));
      const ip = specs.vip_address;
      let vppr = [];
      port.forEach(x => {
        if (x.allowed_address_pairs[0].ip_address === ip) {
          vppr.push(x.fixed_ips[0].ip_address);
        }
      });
      if (vppr) list.push({ name: 'vppr', value: vppr.join(', ') });
      if (specs.flavor_id)
        list.push({
          name: 'tariff',
          value: specs.flavor_id === this.type.advanced ? 'Расширенный' : 'Базовый',
        });

      if (specs.name) list.push({ name: 'name', value: specs.name });
      if (specs.id) list.push({ name: 'ID', value: specs.id.toString() });
      if (specs.created_at)
        list.push({ name: 'date', value: format(specs.created_at, 'dd.MM.yyyy HH:mm:ss') });
      if (specs.vip_address) list.push({ name: 'port', value: specs.vip_address });
      if (this.float) list.push({ name: 'float', value: this.float });
      return list;
    },
  },
  mounted() {
    // if (this.localDisk === null) this.getLocalDisk();
  },
  methods: {
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
    // getLocalDisk() {
    //   this.$store
    //     .dispatch('moduleStack/isLocalDisk', { id: this.$attrs.id, uuid: this.tariff.id })
    //     .then(async data => {
    //       if (data && data.result === 'ok') {
    //         this.localDisk = data.existslocaldisk;
    //       }
    //     })
    //     .catch(e => console.log(e));
    // },
  },
};
</script>

<i18n>
  {
    "ru": {
      "os": "Источник",
      "name": "Имя",
      "date": "Дата создания",
      "tariff": "Тариф",
      "float": "Плавающий IP",
      "port": "Входящий порт",
      "vppr": "Служебные порты",
      "cpu": "vCPU",
      "ram": "Память",
      "disk": "Локальный диск",
      "networkDisk": "Сетевой диск"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 160px;
      margin: 0.5em 0.75rem;
    }
  }
}

.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
