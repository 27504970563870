import AllocationPools from '@/layouts/Stack/components/AllocationPools';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorOpenStackModal, storeMixin, modals],
  data() {
    return {
      data: {},
      dns: '',
      isProcessing: false,
    };
  },
  methods: {
    editAllocationPools: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'AllocationPools';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: AllocationPools,
          closeOnBackdrop: false,
          props: {
            allocationPools: instance.action.subnet.pools,
            // text: this.$t('actions.dnsname'),
          },
          on: {
            change: data => {
              this.pools = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('prolong.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  const params = {
                    params: {
                      subnet: { allocation_pools: this.pools },
                    },
                    item: 'allocation_pools',
                    id: this.id,
                  };
                  this.updatePrivateSubnet(params)
                    .then(data => {
                      if (data) {
                        this.$emit('change-pool');
                        that.$modals.close();
                        this.showResModal('Пул IP-адресов успешно изменён.');
                      }
                    })
                    .catch(e => {
                      that.$modals.close();
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    updatePrivateSubnet(params) {
      return this.$store.dispatch('moduleStack/updateSubnetDns', params);
    },
  },
};
