<template>
  <div class="resize">
    <div class="l-col">
      <base-input
        v-model="editedPools"
        type="textarea"
        :hint="hintPools"
        :label="$t('pools.label')"
        size="medium"
        :placeholder="$t('pools.placeholder')"
        @input="onChange('pools', $event)"
      />
    </div>
    <!--    <plain-button-->
    <!--      v-if="defaultDnsNames !== dnsNames"-->
    <!--      color="primary"-->
    <!--      class="tariffs-table__prolong"-->
    <!--      @click="getDnsDefault"-->
    <!--    >-->
    <!--      {{  $t('dns.restore') }}-->
    <!--    </plain-button>-->
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
export default {
  name: 'AllocationPools',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    allocationPools: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newDescription: '',
      // allocationPools: [],
      editedPools: '',
    };
  },
  computed: {
    hintPools() {
      return 'Введите значения IP по строчно: \n стартовый IP - конечный IP \n 192.168.10 - 192.168.99';
    },
    // DescriptionName() {
    //   return this.text;
    // },
  },
  mounted() {
    this.preparedPools();
  },
  methods: {
    preparedPools() {
      this.editedPools = this.allocationPools
        .map(x => {
          return `${x.start} - ${x.end}`;
        })
        .join('\n');
    },
    onChange(name, event) {
      if (
        event.split('\n').filter(x => x.includes('-')).length > 0 &&
        event.split('\n').filter(x => x.includes('-')).length ===
          event.split('\n').filter(x => x).length
      ) {
        this.$emit(
          'change',
          event
            .split('\n')
            .filter(x => x)
            .map(x => {
              if (x.length)
                return {
                  start: x.split('-')[0].trim(),
                  end: x.split('-')[1].trim(),
                };
            })
        );
      } else this.$emit('notready');
    },
  },
};
</script>
<i18n>
{
"ru": {
  "pools": {
    "restore": "Вернуть DNS по умолчанию",
    "label": "Пулл распределения IP-адресов:",
    "placeholder": "Пулл распределения IP-адресов..."
  }
}}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;
  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
}
}
</style>
