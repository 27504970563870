var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subnet"},[_c('div',{staticClass:"subnet-info"},[_c('div',{staticClass:"subnet-info__create"},[_c('base-button',{staticClass:"subnet-info__create-btn",attrs:{"icon":_vm.isIcon,"tooltip":{
          content: _vm.text,
          autoHide: false,
          placement: 'auto',
          container: false,
          trigger: 'click hover',
        },"color":_vm.isColor},on:{"click":function($event){_vm.isSubnets !== 0 ? _vm.newSubnet(_vm.tariff) : ''}}},[_vm._v(_vm._s(_vm.$t('newSubnet')))])],1)]),_c('transition',{attrs:{"name":"bubble"}},[_c('page-block',{staticClass:"table"},[_c('tariffs-table-private',{attrs:{"table-head":_vm.tableHead,"dataset":_vm.subnets}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }