<template>
  <div class="resize">
    <div class="l-col">
      <base-input
        v-model="dnsNames"
        type="textarea"
        :hint="hintDns"
        :label="DescriptionName"
        size="medium"
        :placeholder="$t('dns.placeholder')"
        @input="onChange('dns', $event)"
      />
    </div>
    <!--    <plain-button-->
    <!--      v-if="defaultDnsNames !== dnsNames"-->
    <!--      color="primary"-->
    <!--      class="tariffs-table__prolong"-->
    <!--      @click="getDnsDefault"-->
    <!--    >-->
    <!--      {{  $t('dns.restore') }}-->
    <!--    </plain-button>-->
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
export default {
  name: 'SubnetDns',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    dns: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newDescription: '',
      dnsNames: '',
      defaultDnsNames: '',
    };
  },
  computed: {
    hintDns() {
      return `Корректная работа сети гарантируется только при использовании наших DNS-серверов:<br />
${this.defaultDnsNames.split('\n')[0]}<br />
${this.defaultDnsNames.split('\n')[1]}<br />
`;
    },
    DescriptionName() {
      return this.text;
    },
  },
  mounted() {
    this.getDnsDefault();
    // this.newDescription = this.description;
  },
  methods: {
    getDnsDefault() {
      this.dnsNames = this.dns.join('\n');
      this.defaultDnsNames = this.$store.state.moduleStack.dnsDefault.join('\n');
    },
    onChange(name, event) {
      // console.log(event);

      this.$emit(
        'change',
        this.dnsNames
          ? this.dnsNames.split('\n').filter(x => x.length)
          : this.defaultDnsNames.split('\n')
      );
    },
  },
};
</script>
<i18n>
{
"ru": {
  "dns": {
    "restore": "Вернуть DNS по умолчанию",
    "placeholder": "DNS адреса"
  }
}}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;
  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
}
}
</style>
