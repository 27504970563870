<template>
  <div class="storage">
    <transition name="bubble">
      <!--      <div v-if="loading">-->
      <!--        <base-loader class="stack-detail-view__loader-item" />-->
      <!--      </div>-->
      <!--      <page-block v-else class="vps-detail-view__block">-->
      <page-block class="storage-view__block">
        <main-card>
          <div class="addons__label medium-title">
            {{ $t('type') }}
          </div>
          <br />
          <div class="addons">
            <div class="addons__item">
              <!--              <div class="addons__label standart-title">{{ $t('container') }}</div>-->
              <div class="addons__label standart-text">{{ $t('container') }}</div>
              <div v-if="mode === 'base'" class="addons__value standart-text">
                {{ tariff['x-container-read'] === '.r:*,.rlistings' ? 'Публичный' : 'Приватный' }}
              </div>
              <div v-else class="addons__value standart-text">
                <div v-for="category of categories" :key="category.key" class="field-radiobutton">
                  <RadioButton
                    :id="category.key"
                    v-model="selectedCategory"
                    name="category"
                    :value="category"
                    class="addons__value-radio"
                  />
                  <label :for="category.key">{{ $t(category.name) }}</label>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="addons__label medium-title">
            {{ $t('limit') }}
          </div>
          <br />
          <div v-if="mode === 'edit'" class="addons">
            <div class="addons__item">
              <!--              <div class="addons__label standart-title">-->
              <div class="addons__label standart-text">
                {{ $t('currentSize') }}
              </div>
              <div class="addons__value standart-text">
                {{ currentSize }}
              </div>
            </div>
            <div class="addons__item">
              <!--              <div class="addons__label standart-title">-->
              <div class="addons__label standart-text">
                {{ $t('currentCount') }}
              </div>
              <div class="addons__value standart-text">
                {{ currentCount }}
              </div>
            </div>
          </div>

          <div class="addons">
            <div v-for="(item, i) in addons" :key="i" class="addons__item">
              <!--              <div class="addons__label standart-title">-->
              <div class="addons__label standart-text">
                {{ $t(`${item.name}`) }}
              </div>

              <div v-if="mode === 'base'" class="addons__value standart-text">
                <!--                {{ item.value }}-->
                <!--                {{formData[item.name]}}-->
                {{ formData[item.name] ? formData[item.name] : 'ထ' }}
              </div>
              <div v-else>
                <!--                  type="number"-->
                <!--                  :min="item.name === 'x-container-meta-quota-count' ? currentCount : ''"-->
                <base-input
                  v-model="formData[item.name]"
                  size="small"
                  placeholder="ထ"
                  type="number"
                  class="addons__input"
                  @input="onChange(item, $event)"
                >
                </base-input>
                <!--                <InputNumber-->
                <!--                  :id="item"-->
                <!--                  v-model="formData[item.name]"-->
                <!--                  class="p-inputtext-sm addons__input"-->
                <!--                  @input="onChange(item, $event)"-->
                <!--                />-->
              </div>
            </div>
          </div>

          <br />
          <!--          <div class="addons__label medium-title">-->
          <!--            {{ $t('cache') }}-->
          <!--          </div>-->
          <!--          <br />-->

          <!--          <div class="addons">-->
          <!--            <div class="addons__item">-->
          <!--              <div class="addons__label standart-title">cashe-control</div>-->

          <!--              <div v-if="mode === 'base'" class="addons__value standart-text">-->
          <!--                {{ formData['cache-control'] }}-->
          <!--              </div>-->
          <!--              <div v-else>-->
          <!--                <InputText-->
          <!--                  v-model="formData['cache-control']"-->
          <!--                  type="text"-->
          <!--                  class="p-inputtext-sm addons__input"-->
          <!--                  @input="onChange($event)"-->
          <!--                />-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <br />-->

          <div class="addons">
            <div v-if="mode === 'base'" class="addons__item">
              <div class="addons__item-btn">
                <Button
                  type="button"
                  label="Изменить"
                  icon="pi pi-pencil"
                  class="p-button-primary"
                  style="width: 130px"
                  @click="edit"
                ></Button>
              </div>
            </div>
            <div v-else class="addons__item">
              <div class="addons__item-btn">
                <Button
                  type="button"
                  label="Сохранить"
                  icon="pi pi-check"
                  class="p-button-primary"
                  style="width: 130px"
                  @click="send"
                ></Button>
              </div>
              <div class="addons__item-btn">
                <Button
                  type="button"
                  label="Отмена"
                  icon="pi pi-times"
                  style="width: 130px"
                  class="p-button-danger"
                  @click="cancel"
                ></Button>
              </div>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import RadioButton from 'primevue/radiobutton';
import providerChange from '@/mixins/providerChange';
import { OPStorage } from '@/models/OP/OPStorage';
import { objectSizeParser } from '@/utils/parsers/objectSizeParser';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import BaseInput from '@/components/BaseInput/BaseInput';
export default {
  name: 'ObjectsView',
  components: {
    MainCard,
    // InputNumber,
    BaseInput,
    // InputText,
    RadioButton,
    Button,
  },
  mixins: [providerChange],
  props: {
    tariff: {
      type: OPStorage,
      required: true,
      validator: obj => obj instanceof OPStorage,
    },
  },
  data() {
    return {
      mode: 'base',
      selectedCategory: null,
      currentSize: '',
      currentCount: '',
      categories: [
        { name: 'private', key: 'private' },
        { name: 'public', key: 'public' },
      ],
      formData: {},
      // isLoading: true,
    };
  },
  computed: {
    project() {
      return this.$store.getters['moduleStack/project_id'];
    },
    size() {
      return this.tariff['x-container-meta-quota-bytes'];
    },
    count() {
      return this.tariff['x-container-meta-quota-count'];
    },
    addons() {
      const specs = this.tariff;
      let list = [];
      if (specs['x-container-meta-quota-bytes'])
        list.push({
          name: 'x-container-meta-quota-bytes',
          value: specs['x-container-meta-quota-bytes'] / 1024 ** 3,
        });
      else list.push({ name: 'x-container-meta-quota-bytes', value: '-' });
      if (specs['x-container-meta-quota-count'])
        list.push({
          name: 'x-container-meta-quota-count',
          value: +specs['x-container-meta-quota-count'],
        });
      else list.push({ name: 'x-container-meta-quota-count', value: '-' });

      // if (specs.name) list.push({ name: 'name', value: specs.name });
      // if (specs.id) list.push({ name: 'ID', value: specs.id.toString() });
      return list;
    },
  },
  watch: {
    // formData: {
    //   handler: function (event) {
    //     console.log(event);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    tariff: {
      handler: function (event) {
        console.log('tariff', event);
        if (event) {
          this.currentSize = objectSizeParser(event.bytes);
          this.currentCount = event.count;
          this.selectedCategory =
            event['x-container-read'] === '.r:*,.rlistings'
              ? { key: 'public', name: 'public' }
              : { key: 'private', name: 'private' };
          if (event.headers) {
            // if (event.headers['cache-control'])
            //   this.formData['cache-control'] = event.headers['cache-control'];
            if (event.headers['x-container-meta-quota-bytes'])
              this.formData['x-container-meta-quota-bytes'] =
                event.headers['x-container-meta-quota-bytes'] / 1024 ** 3;
            // else this.formData['x-container-meta-quota-bytes'] = '-';
            if (event.headers['x-container-meta-quota-count'])
              this.formData['x-container-meta-quota-count'] =
                +event.headers['x-container-meta-quota-count'];
            // else this.formData['x-container-meta-quota-count'] = '-';
            // console.log(event.headers);
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.updateFormData(this.tariff);
  },
  methods: {
    updateFormData(event) {
      console.log('updateFormData');
      if (event) {
        this.currentSize = objectSizeParser(event.bytes);
        this.currentCount = event.count;
        this.selectedCategory =
          event['x-container-read'] === '.r:*,.rlistings'
            ? { key: 'public', name: 'public' }
            : { key: 'private', name: 'private' };
        if (event.headers) {
          // if (event.headers['cache-control'])
          //   this.formData['cache-control'] = event.headers['cache-control'];
          if (event.headers['x-container-meta-quota-bytes'])
            this.formData['x-container-meta-quota-bytes'] =
              event.headers['x-container-meta-quota-bytes'] / 1024 ** 3;
          // else this.formData['x-container-meta-quota-bytes'] = '-';
          if (event.headers['x-container-meta-quota-count'])
            this.formData['x-container-meta-quota-count'] =
              +event.headers['x-container-meta-quota-count'];
          // else this.formData['x-container-meta-quota-count'] = '-';
          // console.log(event.headers);
        }
      }
    },
    // async onProviderChange() {
    //   await this.$store.dispatch('moduleStack/reset', 16);
    // },
    edit() {
      this.mode = 'edit';
    },
    cancel() {
      this.mode = 'base';
      this.currentSize = objectSizeParser(this.tariff.bytes);
      this.currentCount = this.tariff.count;
      // if (this.tariff.headers['cache-control'])
      this.formData['cache-control'] = this.tariff.headers['cache-control'];
      this.formData['x-container-meta-quota-bytes'] =
        this.tariff.headers['x-container-meta-quota-bytes'] / 1024 ** 3;
      this.formData['x-container-meta-quota-count'] =
        this.tariff.headers['x-container-meta-quota-count'];
      this.selectedCategory =
        this.tariff['x-container-read'] === '.r:*,.rlistings'
          ? { key: 'public', name: 'public' }
          : { key: 'private', name: 'private' };
    },
    onChange(name, event) {
      console.log(name.name);
      this.formData[name] = event;
      // console.log(name, '------', this.formData[name], '-------', event);
    },
    async getCurrentStorage() {
      const paramsHead = {
        project: this.project,
        // name: `${this.currentStorage}/${name}`,
        name: this.tariff.name,
        type: 'head',
      };
      const paramsGet = {
        project: this.project,
        // name: `${this.currentStorage}/${name}`,
        name: this.tariff.name,
        type: 'get',
      };

      return new Promise(async resolve => {
        await this.$store.dispatch('moduleStack/fetchStorages', paramsGet).then(async data => {
          // console.log(data);
          await this.$store.dispatch('moduleStack/fetchStorages', paramsHead);
          resolve(data);
        });
      });
    },
    send() {
      const params = {
        project: this.project,
        storage: this.tariff.name,
        headers: {},
      };
      params.headers['x-container-meta-quota-count'] = this.formData['x-container-meta-quota-count']
        ? this.formData['x-container-meta-quota-count']
        : '';
      params.headers['x-container-meta-quota-bytes'] = this.formData['x-container-meta-quota-bytes']
        ? this.formData['x-container-meta-quota-bytes'] * 1024 ** 3
        : '';
      // params.headers['cache-control'] = this.formData['cache-control'];
      params.headers['x-container-read'] =
        this.selectedCategory.key === 'public' ? '.r:*,.rlistings' : '';
      this.mode = 'base';

      return this.$store
        .dispatch('moduleStack/updateStorage', params)
        .then(data => {
          console.log(data);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(async () => {
          await this.getCurrentStorage();
        });
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "os": "Источник",
      "name": "Имя",
      "cache": "Кэширование",
      "container": "Тип контейнера",
      "type": "Настройки конфиденциальности",
      "x-container-meta-quota-bytes": "Суммарный размер, ГБ",
      "x-container-meta-quota-count": "Количество, шт",
"text": "Для сброса лимита - уберите символы из соответствующих полей. Настройки лимитов применяются только при загрузке новых файлов и не влияют на уже хранящиеся файлы.",
      "sum": "Суммарный размер, ГБ",
      "currentSize": "Текущий объем контейнера",
      "currentCount": "Текущее количество объектов",
      "private": "Приватный",
      "public": "Публичный",
      "count": "Количество, шт",
      "limit": "Лимиты объектов контейнера",
      "port": "Входящий порт",
      "vppr": "Служебные порты",
      "cpu": "vCPU",
      "ram": "Память",
      "disk": "Локальный диск",
      "networkDisk": "Сетевой диск"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.storage {
  max-width: 50rem
}
.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 130px;
      margin: 0.5em 0.75rem;
    }
  }
}

.addons {

  &__input{
   //min-width: 20rem
    height: 36px;
   width: 100%
  }
  &__value{
    min-width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;




  &-radio {
      margin-right: 0.5rem
    }
  }

  &__item {
    //margin-top: 1.5rem;
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
      height: 45px;
    }

    &-btn {
      //min-width: 160px;
      width: 130px;
      margin: 0.5em 0.75rem;
    }

    & + & {
      //margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        //border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
