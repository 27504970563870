<template>
  <div class="resize">
    <div class="l-col">
      <!--      <label class="typo__label">{{ $t('subnetwork.label') }}</label>-->
      <base-input
        :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
        use-reactive-validation
        :pattern="patternIP"
        :value="subnet"
        :label="$t('subnetwork.label')"
        class="form__field--input"
        @input="onChange('subnet', $event)"
      >
      </base-input>
    </div>
    <div class="l-col">
      <!--    <div>-->
      <base-checkbox
        :key="checkGateway.key"
        :name="checkGateway.name"
        :value="checkGateway.value"
        :true-value="checkGateway.trueValue"
        :false-value="checkGateway.falseValue"
        class="security-access-limit__field"
        :class="{ ['security-access-limit__field--full']: checkGateway.key === 'checkGateway' }"
        @change="onCheck('checkGateway', $event)"
      >
        {{ checkGateway.label }}
      </base-checkbox>
    </div>
    <div v-if="checkGateway.value === 'off'" class="l-col">
      <base-input
        use-reactive-validation
        :readonly="true"
        :label="$t('gateway.label')"
        :value="gateway ? gateway : computeGateway"
        class="form__field--input"
        @input="onChange('gateway', $event)"
      >
      </base-input>
    </div>
    <!--    </div>-->
    <div class="l-col dns">
      <base-checkbox
        :key="formData.dhcp.key"
        :name="formData.dhcp.name"
        :hint="hintDns"
        :value="formData.dhcp.value"
        :true-value="formData.dhcp.trueValue"
        :false-value="formData.dhcp.falseValue"
        class="security-access-limit__field"
        :class="{ ['security-access-limit__field--full']: formData.dhcp.key === 'dhcp' }"
        @change="onCheck('dhcp', $event)"
      >
        {{ formData.dhcp.label }}
      </base-checkbox>
      <plain-button
        v-if="defaultDnsNames !== dnsNames"
        color="primary"
        class="tariffs-table__prolong"
        @click="getDnsDefault"
      >
        {{ $t('dns.restore') }}
      </plain-button>
    </div>
    <div v-if="formData.dhcp.value === 'on'" class="l-col">
      <base-input
        v-model="dnsNames"
        type="textarea"
        size="medium"
        :placeholder="$t('dns.placeholder')"
        @input="onChange('dns', $event)"
      />
    </div>
  </div>
  <!--  </div>-->
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox.vue';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
export default {
  name: 'NewSubnet',
  components: { BaseInput, BaseCheckbox },
  mixins: [setFocus],
  props: {
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dnsNames: '',
      defaultDnsNames: '',
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('error') },
      },
      value: '',
      // network: '',
      subnet: '192.168.0.0/24',
      subnetAddr: '',
      mask: '',
      gateway: null,
      lastIpSection: '',
      formData: {
        subnet: '',
        net: this.instance.id,
        gateway: '',
        dhcp: {
          value: 'on',
          key: 'dhcp',
          label: 'Включить DHCP',
          trueValue: 'on',
          falseValue: 'off',
        },
      },
      checkGateway: {
        value: 'off',
        key: 'gateway',
        label: 'Запретить шлюз',
        trueValue: 'on',
        falseValue: 'off',
      },
    };
  },
  computed: {
    isGateway() {
      return this.checkGateway.value === 'on';
    },
    hintDns() {
      return `Корректная работа сети гарантируется только при использовании наших DNS-серверов:<br />
${this.defaultDnsNames.split('\n')[0]}<br />
${this.defaultDnsNames.split('\n')[1]}<br />
`;
    },
    sizeSubnet() {
      return +this.subnet.split('/')[1] || null;
    },
    computeGateway() {
      return this.isGateway
        ? null
        : `${this.getMask(this.subnet)}${this.getLastIpSection(this.subnet)}`;
    },
    patternIP() {
      return this.isIPv4(this.mask) &&
        this.lastIpSection === 0 &&
        this.subnetAddr > 23 &&
        this.subnetAddr < 30
        ? `${this.mask}${this.lastIpSection}/${this.subnetAddr}`
        : 'null';
    },
  },
  watch: {
    computeGateway: function (event) {
      this.gateway = '';
      if (!event) return null;
      return this.getLastIpSection(event) === 2
        ? (this.gateway = this.computeGateway)
        : (this.gateway = 'не верный формат подсети');
    },
  },
  mounted() {
    // this.$emit('notready');
    this.getDnsDefault();
    this.initFormdata();
  },
  methods: {
    getDnsDefault() {
      this.dnsNames = this.$store.state.moduleStack.dnsDefault.join('\n');
      this.defaultDnsNames = this.dnsNames;
    },
    onCheck(name, val) {
      name === 'dhcp' ? (this.formData[name].value = val) : (this[name].value = val);
      return this.$emit('change', {
        net: this.instance.id,
        subnet: this.subnet,
        allocation_pools: this.allocationPools(this.sizeSubnet),
        gateway: this.computeGateway ? this.computeGateway : null,
        dhcp: this.formData.dhcp.value,
      });
    },
    allocationPools(ip) {
      let pools = [];
      if (ip === 24) {
        pools.push({
          end: `${this.getMask(this.subnet)}254`,
          start: `${this.getMask(this.subnet)}2`,
        });
        // return pools;
      } else if (ip === 25) {
        pools.push({
          end: `${this.getMask(this.subnet)}126`,
          start: `${this.getMask(this.subnet)}2`,
        });
        // return pools;
      } else if (ip === 26) {
        pools.push({
          end: `${this.getMask(this.subnet)}62`,
          start: `${this.getMask(this.subnet)}2`,
        });
        // return pools;
      } else if (ip === 27) {
        pools.push({
          end: `${this.getMask(this.subnet)}30`,
          start: `${this.getMask(this.subnet)}2`,
        });
      } else if (ip === 28) {
        pools.push({
          end: `${this.getMask(this.subnet)}14`,
          start: `${this.getMask(this.subnet)}2`,
        });
      } else if (ip === 29) {
        pools.push({
          end: `${this.getMask(this.subnet)}6`,
          start: `${this.getMask(this.subnet)}2`,
        });
      }

      return pools;
      // return { start: `${this.getMask(this.subnet)}2`, end: `${this.getMask(this.subnet)}254` };
    },
    initFormdata() {
      return this.$emit('change', {
        net: this.instance.id,
        subnet: this.subnet,
        allocation_pools: this.allocationPools(this.sizeSubnet),
        gateway: this.computeGateway,
        dhcp: this.formData.dhcp.value,
        dnsNames: this.dnsNames ? this.dnsNames.split('\n') : this.defaultDnsNames.split('\n'),
      });
    },
    isIPv4(addr) {
      return /^(([01]?\d{1,2}|2[0-4]\d|25[0-5])(\.|$)){3}$/.test(addr);
    },
    getLastIpSection(ip) {
      this.lastIpSection = +ip.split('/').at(0).split('.').at(-1);
      this.subnetAddr = +ip.split('/').at(-1);
      return this.lastIpSection + 1;
    },
    getMask(ip) {
      this.mask = ip.slice(0, ip.lastIndexOf('.') + 1);
      return this.mask;
    },
    onChange(name, event) {
      if (this[name] === 'subnet') {
        console.log('subnet');
      }
      this[name] = event;
      if (this.subnet && !this.computeGateway) {
        return this.$emit('change', {
          net: this.instance.id,
          subnet: this.subnet,
          allocation_pools: this.allocationPools(this.sizeSubnet),
          gateway: null,
          dnsNames: this.dnsNames ? this.dnsNames.split('\n') : this.defaultDnsNames.split('\n'),
          dhcp: this.formData.dhcp.value,
        });
      } else if (this.subnet && this.computeGateway) {
        return this.$emit('change', {
          net: this.instance.id,
          subnet: this.subnet,
          allocation_pools: this.allocationPools(this.sizeSubnet),
          gateway: this.computeGateway,
          dhcp: this.formData.dhcp.value,
          dnsNames: this.dnsNames ? this.dnsNames.split('\n') : this.defaultDnsNames.split('\n'),
        });
      } else return this.$emit('notready');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "subnetwork": {
      "label": "CIDR подсети:",
      "placeholder": "",
      "gateway": "Шлюз:",
      "dhcp": "Состояние"
    },
    "dns": {
      "restore": "Вернуть DNS по умолчанию",
      "placeholder": "DNS адреса"
    },
    "network": {
      "label": "Имя приватной сети",
      "placeholder": "Имя сети"
    },
    "gateway": {
      "label": "Шлюз:",
      "placeholder": "Имя сети"
    },
    "sure": {
      "confirm": "Увеличить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.dns {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
